import React from 'react'

import Manufacturers from '../../components/views/settings/manufacturers/manufacturers'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function ManufacturersPage() {
   return (
      <LayoutLoggedIn>
         <Manufacturers />
      </LayoutLoggedIn>
   )
}
