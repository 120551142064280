import React, { useState, useEffect, useMemo, useRef } from 'react'
import { TableWrapper } from '../../../common/table/TableWrapper'
import {
   PageHeader,
   Search,
   Modal,
   CrudActions,
   Button,
   CrudActionButtons,
   SnackBar,
   Svg,
} from '../../../common'
import { Actions, PAGINATION, ACTION_TITLE } from '../../../../constants/tables'
import { Severity } from '../../../../types'
import { useCrudItems } from '../../../../hooks'
import { CRUD_TABLE } from './constants'

const {
   extraClassName,
   primaryActionText,
   secondaryActionText,
   primaryDeleteText,
   modalTitle,
   placeholder,
   defaultActiveValue,
   defaultActionState,
   modalAddTitle,
   addButtonText,
   key,
   objectKey,
   objectKeyTitleCase,
   crudPaths,
   tableTitle,
   header,
} = CRUD_TABLE

const Manufacturers = () => {
   const { query, skip, take } = PAGINATION
   const {
      data: crudData,
      get,
      update,
      add,
      remove,
      totalItems,
      setPath,
      isLoading,
      isSuccessCreate,
      isErrorCreate,
      isSuccessUpdate,
      isErrorUpdate,
      isSuccessRemove,
      isErrorRemove,
   } = useCrudItems({ query, skip, take })

   const inputRef = useRef<HTMLInputElement>()
   const buttonRef = useRef<HTMLInputElement>()
   const initialState = {
      disableAutoClose: false,
      message: '',
      severity: Severity.SUCCESS,
   }

   const [searchMode, setSearchMode] = useState(false)
   const [pageSized, setPageSized] = useState(PAGINATION.pageSized)
   const [isPageReset] = useState(false)
   const [actionState, setActionState] = useState(defaultActionState)
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [activeValue, setActiveValue] = useState(defaultActiveValue)
   const [searchCriteria, setSearchCriteria] = useState('')
   // @ts-ignore
   const [open, setOpen] = useState(false)
   const [state, setState] = useState(initialState)

   const handleGetItems = (query?: string, skip?: number, take?: number) => {
      get(query, skip, take)
   }

   const handleSearch = (query?: string, skip?: number, take?: number) => {
      get(query, skip, take)
   }

   const updateItem = (obj) => {
      if (obj.objectKey !== '') {
         update(obj)
      }
   }

   const addItem = (obj) => {
      add(obj)
   }

   const removeItem = (id) => {
      remove(id)
   }

   const showActionForm = (action, id?) => {
      // Keep
      if (action === Actions.Delete || action === Actions.Edit) {
         setActiveValue({
            id,
            name: crudData.find(
               (item: { Id: number | string }) => item.Id === id
            )?.[objectKeyTitleCase],
         })
      }
      setActionState(action)
      setIsModalVisible(true)
   }

   const handleActionForm = (action, id?, value?) => {
      switch (action) {
         case Actions.Add:
            addItem({
               [objectKey]: value,
            })
            break
         case Actions.Edit:
            updateItem({
               id,
               [objectKey]: value,
            })
            break
         case Actions.Delete:
            removeItem(id)
            break
      }
      setIsModalVisible(false)
   }

   const handleClose = () => {
      setIsModalVisible(false)
      setActiveValue(defaultActiveValue)
      inputRef.current.value = ''
      buttonRef.current.click()
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'Id',
            Cell: (cell: any) => (
               <CrudActionButtons
                  cell={cell}
                  action={showActionForm}
                  editAction
                  deleteAction
               />
            ),
         },
         {
            Header: header,
            accessor: objectKeyTitleCase,
            width: 1000,
         },
      ],
      [crudData]
   )

   useEffect(() => {
      setPath(crudPaths, key)
   }, [])

   function handleMessage(message, severity) {
      setState({ ...state, message, severity })
   }

   // handle actions
   useEffect(() => {
      handleMessage('Manufacturer has been updated.', Severity.SUCCESS)
   }, [isSuccessUpdate])

   useEffect(() => {
      handleMessage('Manufacturer has been created.', Severity.SUCCESS)
   }, [isSuccessCreate])

   useEffect(() => {
      handleMessage('Manufacturer has been removed.', Severity.SUCCESS)
   }, [isSuccessRemove])

   // handle errors
   useEffect(() => {
      handleMessage(
         'Creating a manufacturer has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorCreate])

   useEffect(() => {
      handleMessage(
         'Updating a manufacturer has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorUpdate])

   useEffect(() => {
      handleMessage(
         'Deleting a manufacturer has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorRemove])
   // set into one varible for snackbar bool
   const showSnackbar =
      isSuccessUpdate ||
      isSuccessCreate ||
      isSuccessRemove ||
      isErrorCreate ||
      isErrorUpdate ||
      isErrorRemove

   return (
      <div>
         <SnackBar
            message={state.message}
            open={showSnackbar}
            onClose={() => setOpen(false)}
            severity={state.severity}
            disableAutoClose={state.disableAutoClose}
         />
         {!isLoading && (
            <>
               <PageHeader title={tableTitle}>
                  <Button
                     onClick={() => showActionForm(Actions.Add, '')}
                     preserveText
                     variant="plain"
                  >
                     <Svg id="plus" /> {addButtonText}
                  </Button>
               </PageHeader>
               <Search
                  handleQuery={handleSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
               />
               <TableWrapper
                  isLoading={isLoading}
                  data={crudData}
                  columns={columns}
                  totalCount={totalItems}
                  getItems={handleGetItems}
                  takeItems={PAGINATION.take}
                  skipItems={PAGINATION.skip}
                  setSearchMode={setSearchMode}
                  searchMode={searchMode}
                  query={query}
                  isPageReset={isPageReset}
                  setPageSized={setPageSized}
                  extraClassName={extraClassName}
                  searchCriteria={searchCriteria}
               />
               <Modal
                  isModalVisible={isModalVisible}
                  closeModal={handleClose}
                  title={ACTION_TITLE[actionState] + modalTitle}
               >
                  <CrudActions
                     actionState={actionState}
                     primaryActionText={primaryActionText}
                     secondaryActionText={secondaryActionText}
                     primaryDeleteText={primaryDeleteText}
                     id={activeValue.id}
                     name={activeValue.name}
                     modalAddTitle={modalAddTitle}
                     handleAction={handleActionForm}
                     handleClose={handleClose}
                     inputRef={inputRef}
                     buttonRef={buttonRef}
                  />
               </Modal>
            </>
         )}
      </div>
   )
}

export default Manufacturers
