import { PATH } from '../../../../constants/global'
import { TABLE, CRUD } from '../../../../constants/tables'

export const CRUD_TABLE = {
    extraClassName: 'table-actions',
    params: '?manufacturerId=',
    modalTitle: ' Manufacturer',
    placeholder: ' manufacturers',
    addButtonText: 'Add Manufacturer',
    modalAddTitle: 'Name',
    key: 'Manufacturers',
    defaultActiveValue: { id: '', name: ''},
    defaultActionState: 0,
    objectKey: 'name',
    objectKeyTitleCase: 'Name',
    header: 'Name',
    primaryActionText: CRUD.primaryActionText,
    secondaryActionText: CRUD.secondaryActionText,
    primaryDeleteText: CRUD.primaryDeleteText,
    crudPaths: PATH.MANUFACTURERS,
    tableTitle: TABLE.TITLE.MANUFACTURERS,
}